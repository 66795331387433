import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { services } from '../../services/index'

// First, create the thunk
export const fetchCoin = createAsyncThunk(
  'coin/list',
  async () => {
    const response = await services.get(`/api/coin/list`)
    return response.data.data
  }
)

const initialState = {
  list: [],
  curCoin: {}
}

// Then, handle actions in your reducers:
const coinSlice = createSlice({
  name: 'coin',
  initialState,
  reducers: {
    selectChain: (state, action) => {
      state.curCoin = action.payload
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchCoin.fulfilled, (state, action) => {
      state.list = action.payload
    })
  }
})


export default coinSlice.reducer
