import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { services } from '../../services/index'

// First, create the thunk
export const fetchNonce = createAsyncThunk(
  'user/nonce',
  async (address) => {
    const response = await services.get(`/api/admin/get-nonce/${address}`)
    return response.data.data
  }
)

export const authLogin = createAsyncThunk(
  'auth/login',
  async (data) => {

    const url = "/api/auth/signin";
    const response = await services.post(url, { nonce: data.nonce, signature: data.signature });
    if (response?.status === 200) {
      localStorage.setItem("inoRole", response.data?.data?.role?.name);
      localStorage.setItem("liquidToken", response.data?.data?.token); // store user auth token
      if (response.data.data.token) {
        localStorage.setItem("userAddress", response.data?.data?.address); // store user address
        localStorage.setItem("userId", response.data?.data?._id); // store user id
      }
    } else {
      localStorage.setItem("liquidToken", "");
      localStorage.setItem("inoRole", "");
    }
    return response.data.data
  }
)


const initialState = {
  user: {},
}

// Then, handle actions in your reducers:
const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchNonce.fulfilled, (state, action) => {
      state.user = {
        ...state.user,
        nonce: action.payload?.nonce
      }
    })
    builder.addCase(authLogin.fulfilled, (state, action) => {
      state.user = action.payload
    })
  }
})


export default userSlice.reducer
