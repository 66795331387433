import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { services } from '../../services/index'

// First, create the thunk
export const fetchChain = createAsyncThunk(
  'chain/list',
  async () => {
    const response = await services.get(`/api/chain/list`)
    return response.data.data
  }
)

const initialState = {
  list: [],
  curChain: {}
}

// Then, handle actions in your reducers:
const chainSlice = createSlice({
  name: 'chain',
  initialState,
  reducers: {
    selectChain: (state, action) => {
      state.curChain = action.payload
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchChain.fulfilled, (state, action) => {
      state.list = action.payload
    })
  }
})

export const { selectChain } = chainSlice.actions

export default chainSlice.reducer
