import { api } from '../config';
import { Toast } from '../helpers/toastify.message'

export const backendServices = {
  get,
  post,
  put,
};

async function post(url, params) {

  return api.post(url, params,)
    .then(response => {
      return response;
    })
    .catch((error) => {
      console.log(error)
      // Error
      if (error.response) {
        const pathname = window.location.pathname
        Toast.error(error.response.data.message)
        if (pathname === '/register') return error.response
      } else if (error.request) {
        Toast.error(error.request)
      } else {
        Toast.error(error.message)
      }
    });
}

async function get(url) {

  return api.get(url)
    .then(response => {
      return response;
    })
    .catch((error) => {
      // Error
      if (error.response) {
        Toast.error(error.response.data.message)
      } else if (error.request) {
        Toast.error(error.request)
      } else {
        Toast.error(error.message)
      }
    });
}

async function put(url, params) {

  return api.put(url, params)
    .then(response => {
      return response;
    })
    .catch((error) => {
      // Error
      if (error.response) {
        Toast.error(error.response.data.message)
      } else if (error.request) {
        Toast.error(error.request)
      } else {
        Toast.error(error.message)
      }
    });
}
