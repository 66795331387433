import { createSlice } from '@reduxjs/toolkit'

export const proposalModal = createSlice({
  name: 'proposalModal',
  initialState: {
    value: false,
  },
  reducers: {
    proposalModalAction: (state, action) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {proposalModalAction } = proposalModal.actions

export default proposalModal.reducer