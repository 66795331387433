import axios from "axios";

export const backendURL = "https://sbs-form.dreserver.com";

// backend api url
let apiInstance = axios.create({
  baseURL: backendURL, // Server url
  // baseURL: "https://inoapi.seedify.fund/api/v1",
});

export const PINATA_API_KEY = "124b759bfe1c30438e26";
export const PINATA_API_SECRET = "2f48806372702d07a997d175eed98549c9ae7a83e695695976b999c3dd989459";


apiInstance.interceptors.request.use((config) => {
    const tokenDetailsString = localStorage.getItem('liquidToken');
    if(tokenDetailsString) {
        config.params = config.params || {};
        config.headers['x-auth-token'] = `${tokenDetailsString}`
    }

    return config;
});

export const api = apiInstance;

export const durations = [
    {
        id: "0",
        label: "30 Days",
        multiple: "0.8x",
        apr: "4%",
        time: 3600*24*30
    },{
        id: "1",
        label: "90 Days",
        multiple: "1.0x",
        apr: "5%",
        time: 3600*24*90
    },{
        id: "2",
        label: "180 Days",
        multiple: "1.2x",
        apr: "6%",
        time: 3600*24*180
    },{
        id: "3",
        label: "270 Days",
        multiple: "1.4x",
        apr: "7%",
        time: 3600*24*270
    }
]

export const legacyDurations = [
    {
        id: "0",
        label: "30 Days",
        multiple: "0.8",
        apr: "4%",
    },{
        id: "1",
        label: "90 Days",
        multiple: "0.5",
        apr: "5%",
    },{
        id: "2",
        label: "180 Days",
        multiple: "1.2",
        apr: "6%",
    },{
        id: "3",
        label: "270 Days",
        multiple: "1.4",
        apr: "7%",
    }
]

export const ipfsURL = "https://gateway.pinata.cloud/ipfs/";
export const etherscanURL = "https://etherscan.io/";
export const projectName = "DRE";
export const SecurityTokenName = "AUDCT";
export const UtilityTokenName = "ITR";
export const LPTokenName = "AUDCT LP";
export const PROJECT_VISIBLE_NOT_STARTED ="NOT_STARTED";
export const PROJECT_VISIBLE_OPENED ="OPENED";
export const PROJECT_VISIBLE_PAUSED ="PAUSED";
export const PROJECT_VISIBLE_NOT_DEPLOYED ="NOT_DEPLOYED";
export const PROJECT_VISIBLE_COMPLETED = "COMPLETED";

export const PLATFORM_TYPE_STAKING_IGO = "STAKING_IGO";
export const PLATFORM_TYPE_STAKING_IDO = "STAKING_IDO";
export const PLATFORM_TYPE_FARMING_IDO = "FARMING_IDO";
export const PLATFORM_TYPE_INVEST_IGO = "PLATFORM_TYPE_INVEST_IGO";
export const TX_TYPE_DEPOSIT = "DEPOSIT";
export const TX_TYPE_WITHDRAW = "WITHDRAW";
export const TX_TYPE_SWAP = "SWAP";
export const TX_TYPE_REFUND = "REFUND";
export const TX_TYPE_BUY_IGO = "BUY_IGO";
export const TX_TYPE_CLAIM_IGO = "CLAIM_IGO";
export const TX_TYPE_LOCK = "LOCK";
export const PAYMENT_METHOD_CRYPTO = "CRYPTO";
export const PAYMENT_METHOD_STRIPE = "STRIPE";
export const PAYMENT_METHOD_PAYPAL = "PAYPAL";
export const TX_STATUS_SUCCESS = "SUCCESS"
export const TX_STATUS_FAIL = "FAIL";

export const PROPOSAL_STATE = {
    "0": "Pending",
    "1": "Active",
    "2": "Canceled",
    "3": "Defeated",
    "4": "Succeeded",
    "5": "Queued",
    "6": "Expired",
    "7": "Executed",
}