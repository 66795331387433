import { configureStore } from '@reduxjs/toolkit'
import toggleReducer from '../slice/ToggleSlice';
import walletReducer from '../slice/AddWalletSlice';
import UserSlice from '../slice/UserSlice';
import ChainSlice from '../slice/ChainSlice';
import CoinSlice from '../slice/CoinSlice';
import proposalModalSlice from '../slice/ProposalModal';
import GlobalSlice from '../slice/GlobalSlice';

export default configureStore({
    reducer: {
        toggle: toggleReducer,
        addWallet: walletReducer,
        auth: UserSlice,
        chain: ChainSlice,
        coin: CoinSlice,
        global: GlobalSlice,
        proposalModal: proposalModalSlice
    },
})