import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  debug: true,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  // language resources
  resources: {
    en: {
      translation: {
        how_it_works: "How it Works",
        pre_sale_app: "Presale dapp",
        staking_dapp: "Staking dapp",
        token_nomics: "Tokennomics",
        invest: "Invest",
        traducer: "Traducer",
        english: "English",
        spanish: "Spanish",
        russian: "Russian",
        chinesse: "Chinese",
        arabic: "Arabic",
        blockchain: "Blockchain",
        in: "in",
        realestate: "Real Estate",
        real_desciption: "Real estate in the blockchain era is undergoing a shift. Blockchain technology revolutionizing property transactions.",
        whitepapper: "Whitepapper",
        ourmission: "OUR MISION",
        ourmission_desp_1: "Our mission is to join forces in the fight against unemployment rates, climate change, and the housing crisis affecting homeless families.",
        ourmission_desp_2: "We pledge to use real-world assets to promote sustainable and eco-friendly tourism, reduce carbon footprint, and create a positive social impact.",
        real_state_title: "Real state by Fractions & Green Energy",
        how_it_work: "How It Work",
        real_world: "Real World & Virtual World Off-plan Projects",
        real_world_desp_1: "Each property is represented in an ERC-1400 Token, a security standard for",
        real_world_desp_2: "realty",
        real_world_desp_3: "1 Token = 10 m2 built",
        multi_security: "Multi-signature security",
        investments: "Investments By Fraction",
        invest_heading: "A charm in the mountains",
        san_address: "San Andress islands. Colombia",
        build_time: "Build Time: 115D: 17H: 40M",
        cost_per: "Cost per share",
        soft_cap: "Softcap",
        soft_value: "22 TinyHouses",
        security: "Security",
        security_value: "Prooft reserve + Multising protocol(Safe)",
        rent_value: "Rent Value",
        month: "month",
        goal: "Goal",
        invest_now: "Invest NOW",
        passive: "Passive Income",
        passive_description: "Earn rental income and receive quarterly deposits",
        transparency: "Transparency in processes",
        transparency_description: "We use blockchain and a dapp of governance to inspect each Process and authorize disbursements by stages of constructionWe use blockchain and a dapp of governance to inspect each Process and authorize disbursements by stages of construction",
        multi_singnature: "Multi-signature security (22-41)",
        multi_singnature_description: "Digital security is legal Compatible and tangible for investors Qualified",
        home: "Home",
        tech_details: "How it Works",
        fwcc: "FWCC",
        pre: "Pre",
        sale: "Sale",
        sale_ens: "Sale ends in",
        days: "days",
        hours: "Hours",
        and: "and",
        minutes: "Minutes",
        connect: "Connect",
        wallet: "Wallet",
        openfabric: "Openfabric AI",
        openfabric_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        vesting: "Vesting",
        vesting_description: "Lorem ipsum dolor sit amet.",
        total_rasied: "Total Raised",
        progress: "Progress",
        limited: "Limited",
        participants: "Participants",
        approve: "Approve",
        starts_in: "Starts in",
        referral: "Referral System",
        referral_description: "Share your referral link and instantly earn 5% in your wallet for each referred token purchase. The referral cas will be paid in the same token used for payment, USDT, USDC, or WBNIB",
        copy_link: "Copy Link",
        token_information: "Token Information",
        token_ticker: "Token Ticker",
        token_supply: "Token Supply",
        network: "Network",
        token_address: "Token Address",
        decimal: "Decimals",
        token_distribution: "Token Distribution",
        min_allocation: "Min, Allocation",
        max_allocation: "Max, Allocation",
        min_swap_level: "Min Swap Level",
        access_type: "Access Type",
        public: "Public"
      },
    },
    es: {
      translation: {
        how_it_works: "¿Cómo funciona?",
        pre_sale_app: "Dapp de preventa",
        staking_dapp: "Dapp de staking",
        token_nomics: "Tokenomics",
        invest: "Invertir",
        traducer: "Traductor",
        english: "Inglés",
        spanish: "Español",
        russian: "Ruso",
        chinesse: "Chino",
        arabic: "Árabe",
        blockchain: "Blockchain",
        in: "en",
        realestate: "Bienes raíces",
        real_desciption: "Los bienes raíces en la era de la blockchain están experimentando un cambio. La tecnología de la blockchain está revolucionando las transacciones de propiedades.",
        whitepapper: "Whitepaper",
        ourmission: "NUESTRA MISIÓN",
        ourmission_desp_1: "Nuestra misión es unir fuerzas en la lucha contra las tasas de desempleo, el cambio climático y la crisis de vivienda que afecta a las familias sin hogar.",
        ourmission_desp_2: "Nos comprometemos a utilizar activos del mundo real para promover el turismo sostenible y ecológico, reducir la huella de carbono y crear un impacto social positivo.",
        real_state_title: "Bienes raíces por Fractions & Green Energy",
        how_it_work: "Cómo funciona",
        real_world: "Proyectos de mundo real y virtual en construcción",
        real_world_desp_1: "Cada propiedad se representa en un Token ERC-1400, un estándar de seguridad para",
        real_world_desp_2: "bienes raíces",
        real_world_desp_3: "1 Token = 10 m2 construidos",
        multi_security: "Seguridad de múltiples firmas",
        investments: "Inversiones por fracción",
        invest_heading: "Un encanto en las montañas",
        san_address: "Islas de San Andrés. Colombia",
        build_time: "Tiempo de construcción: 115D: 17H: 40M",
        cost_per: "Costo por acción",
        soft_cap: "Softcap",
        soft_value: "22 TinyHouses",
        security: "Seguridad",
        security_value: "Reserva de prueba + Protocolo de firma múltiple (Seguro)",
        rent_value: "Valor de alquiler",
        month: "mes",
        goal: "Objetivo",
        invest_now: "Invertir AHORA",
        passive: "Ingreso pasivo",
        passive_description: "Gane ingresos por alquiler y reciba depósitos trimestrales",
        transparency: "Transparencia en los procesos",
        transparency_description: "Utilizamos blockchain y una dapp de gobernabilidad para inspeccionar cada proceso y autorizar desembolsos por etapas de construcción.",
        multi_singnature: "Seguridad de múltiples firmas (22-41)",
        multi_singnature_description: "La seguridad digital es compatible legalmente y tangible para inversores calificados.",
        home: "Inicio",
        tech_details: "Cómo funciona",
        fwcc: "FWCC",
        pre: "Pre",
        sale: "Venta",
        sale_ens: "La venta termina en",
        days: "días",
        hours: "Horas",
        and: "and",
        and: "and",
        minutes: "Minutos",
        connect: "Conectar",
        wallet: "Cartera",
        openfabric: "Openfabric AI",
        openfabric_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        vesting: "Vesting",
        vesting_description: "Lorem ipsum dolor sit amet.",
        progress: "Progress",
        total_rasied: "Total recaudado",
        limited: "Limitado",
        participants: "Participantes",
        approve: "Aprobar",
        starts_in: "Comienza en",
        referral: "Referral System",
        referral_description: "Share your referral link and instantly earn 5% in your wallet for each referred token purchase. The referral cas will be paid in the same token used for payment, USDT, USDC, or WBNIB",
        copy_link: "Copy Link",
        token_information: "Información del token",
        token_ticker: "Ticker del token",
        token_supply: "Suministro de tokens",
        network: "Red",
        token_address: "Dirección del token",
        decimals: "Decimales",
        token_distribution: "Distribución del token",
        min_allocation: "Asignación mínima",
        max_allocation: "Asignación máxima",
        min_swap_level: "Nivel mínimo de intercambio",
        access_type: "Tipo de acceso",
        public: "Público"
      },
    },
    ru: {
      translation: {
        how_it_works: "Как это работает",
        pre_sale_app: "Dapp предпродажи",
        staking_dapp: "Dapp стейкинга",
        token_nomics: "Номика токенов",
        invest: "Инвестировать",
        traducer: "Переводчик",
        english: "Английский",
        spanish: "Испанский",
        russian: "Русский",
        chinesse: "Китайский",
        arabic: "Арабский",
        blockchain: "Блокчейн",
        in: "в",
        realestate: "Недвижимость",
        real_desciption: "Недвижимость в эпоху блокчейна проходит изменения. Технология блокчейна революционизирует сделки с недвижимостью.",
        whitepapper: "Белая бумага",
        ourmission: "НАША МИССИЯ",
        ourmission_desp_1: "Наша миссия - объединить усилия в борьбе с уровнями безработицы, изменением климата и кризисом на рынке жилья, который затрагивает бездомные семьи.",
        ourmission_desp_2: "Мы обещаем использовать активы реального мира для продвижения устойчивого и экологически чистого туризма, снижения углеродного следа и создания положительного социального влияния.",
        real_state_title: "Недвижимость от Fractions & Green Energy",
        how_it_work: "Как это работает",
        real_world: "Реальный мир и виртуальный мир проектов на стадии планирования",
        real_world_desp_1: "Каждый объект недвижимости представлен в виде ERC-1400 токена - стандарта безопасности для",
        real_world_desp_2: "недвижимости",
        real_world_desp_3: "1 токен = 10 кв.м.",
        multi_security: "Многофакторная безопасность",
        investments: "Инвестиции по фракциям",
        invest_heading: "Очарование гор",
        san_address: "Сан-Андрес острова. Колумбия",
        build_time: "Срок строительства: 115Д: 17Ч: 40М",
        cost_per: "Цена за долю",
        soft_cap: "Минимальное значение",
        soft_value: "22 небольших домика",
        security: "Безопасность",
        security_value: "Резерв справок + протокол мультиподписи (Безопасно)",
        rent_value: "Стоимость аренды",
        month: "месяц",
        goal: "Цель",
        invest_now: "Инвестировать СЕЙЧАС",
        passive: "Пассивный доход",
        passive_description: "Заработайте арендную плату и получайте ежеквартальные выплаты",
        transparency: "Прозрачность процессов",
        transparency_description: "Мы используем блокчейн и dapp для управления, чтобы проверять каждый процесс и авторизовывать выделения на каждом этапе строительства.",
        multi_singnature: "Многофакторная безопасность (22-41)",
        multi_singnature_description: "Цифровая безопасность совместима с законодательством и ощутима для квалифицированных инвесторов.",
        home: "Главная",
        tech_details: "Как это работает",
        fwcc: "FWCC",
        pre: "Предварительная",
        sale: "Распродажа",
        sale_ens: "Распродажа заканчивается через",
        days: "дни",
        hours: "часы",
        and: "and",
        minutes: "минуты",
        connect: "Подключить",
        wallet: "Кошелек",
        openfabric: "Openfabric AI",
        openfabric_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        vesting: "Вестинг",
        vesting_description: "Lorem ipsum dolor sit amet.",
        total_rasied: "Всего собрано",
        progress: "Progress",
        limited: "Ограничено",
        participants: "Участники",
        approve: "Авторизовать",
        starts_in: "Начинается через",
        referral: "Referral System",
        referral_description: "Share your referral link and instantly earn 5% in your wallet for each referred token purchase. The referral cas will be paid in the same token used for payment, USDT, USDC, or WBNIB",
        copy_link: "Copy Link",
        token_information: "Информация о токене",
        token_ticker: "Символ токена",
        token_supply: "Общий объем токена",
        network: "Сеть",
        token_address: "Адрес токена",
        decimal: "Десятичные знаки",
        token_distribution: "Распределение токенов",
        min_allocation: "Минимальная аллокация",
        max_allocation: "Максимальная аллокация",
        min_swap_level: "Минимальный уровень свопа",
        access_type: "Тип доступа",
        public: "Публичный"
      }
    },
    
    ch: {
      translation: {
        how_it_works: "工作原理",
        pre_sale_app: "预售dapp",
        staking_dapp: "质押dapp",
        token_nomics: "Tokennomics",
        invest: "投资",
        traducer: "翻译",
        english: "英文",
        spanish: "西班牙文",
        russian: "俄文",
        chinesse: "中文",
        arabic: "阿拉伯文",
        blockchain: "区块链",
        in: "在",
        realestate: "房地产",
        real_desciption: "在区块链时代的房地产正在发生变革。区块链技术正在革新房产交易。",
        whitepapper: "白皮书",
        ourmission: "我们的使命",
        ourmission_desp_1: "我们的使命是联合起来与失业率、气候变化和困扰无家可归家庭的住房危机作斗争。",
        ourmission_desp_2: "我们承诺利用现实世界的资产推动可持续和环保旅游，减少碳排放，并产生积极的社会影响。",
        real_state_title: "分数+绿色能源的房地产",
        how_it_work: "它如何工作",
        real_world: "真实世界和虚拟世界的计划外项目",
        real_world_desp_1: "每个房产都由ERC-1400 Token表示，这是一种用于",
        real_world_desp_2: "房地产的安全标准",
        real_world_desp_3: "1个Token = 10平方米建筑面积",
        multi_security: "多重签名安全",
        investments: "按分数投资",
        invest_heading: "山区的魅力",
        san_address: "哥伦比亚圣安德雷斯群岛",
        build_time: "建造时间：115天17小时40分钟",
        cost_per: "每股成本",
        soft_cap: "软顶",
        soft_value: "22套小别墅",
        security: "安全性",
        security_value: "Prooft储备+ Multising协议(Safe)",
        rent_value: "出租价值",
        month: "月",
        goal: "目标",
        invest_now: "立即投资",
        passive: "被动收入",
        passive_description: "获得租金收入并每季度存款",
        transparency: "流程透明",
        transparency_description: "我们使用区块链和治理dapp来检查每个过程，并按照建设阶段授权拨款",
        multi_singnature: "多重签名安全 (22-41)",
        multi_singnature_description: "数字安全合法、兼容且可触摸以满足投资者的要求",
        home: "主页",
        tech_details: "如何工作",
        fwcc: "FWCC",
        pre: "预",
        sale: "销售",
        sale_ens: "销售结束于",
        days: "天",
        hours: "小时",
        and: "and",
        minutes: "分钟",
        connect: "连接",
        wallet: "钱包",
        openfabric: "Openfabric AI",
        openfabric_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        vesting: "锁仓",
        vesting_description: "Lorem ipsum dolor sit amet.",
        progress: "Progress",
        total_rasied: "已筹集总额",
        limited: "有限的",
        participants: "参与者",
        approve: "批准",
        starts_in: "开始于",
        referral: "Referral System",
        referral_description: "Share your referral link and instantly earn 5% in your wallet for each referred token purchase. The referral cas will be paid in the same token used for payment, USDT, USDC, or WBNIB",
        copy_link: "Copy Link",
        token_information: "代币信息",
        token_ticker: "代币简称",
        token_supply: "代币供应量",
        network: "网络",
        token_address: "代币地址",
        decimal: "小数位数",
        token_distribution: "代币分配",
        min_allocation: "最低配置",
        max_allocation: "最高配置",
        min_swap_level: "最低兑换级别",
        access_type: "访问类型",
        public: "公共"
      },
    },
    ar: {
      translation: {
        how_it_works: "كيف يعمل",
        pre_sale_app: "تطبيق ما قبل البيانات",
        staking_dapp: "تطبيق تراكم العملات",
        token_nomics: "Tokennomics",
        invest: "استثمار",
        traducer: "Traducer",
        english: "الإنجليزية",
        spanish: "الأسبانية",
        russian: "الروسية",
        chinesse: "الصينية",
        arabic: "العربية",
        blockchain: "البلوكشين",
        in: "في",
        realestate: "العقارات",
        real_desciption: "يخضع العقار في عصر البلوكشين لتغيير. تقوم تكنولوجيا البلوكشين بثورة في المعاملات العقارية.",
        whitepapper: "وايت بايبر",
        ourmission: "مهمتنا",
        ourmission_desp_1: "مهمتنا هي الانضمام إلى الجهود في مكافحة معدلات البطالة وتغير المناخ وأزمة الإسكان التي تؤثر على العائلات المشردة.",
        ourmission_desp_2: "نتعهد باستخدام الأصول العالمية لتعزيز السياحة المستدامة وصديقة للبيئة ، بالإضافة إلى تقليل الأثر الكربوني وخلق أثر اجتماعي إيجابي.",
        real_state_title: "العقارات من خلال كسور والطاقة الخضراء",
        how_it_work: "كيف يعمل",
        real_world: "العالم الحقيقي والعالم الافتراضي المشاريع ما قبل التخطيط",
        real_world_desp_1: "يتم تمثيل كل عقار في رمز ERC-1400 ، وهو معيار أمان لـ",
        real_world_desp_2: "عقار",
        real_world_desp_3: "1 رمز = 10 متر مربع بناء",
        multi_security: "أمان متعدد التوقيعات",
        investments: "استثمارات عن طريق الجزء",
        invest_heading: "سحر في الجبال",
        san_address: "جزر سان أندرس. كولومبيا",
        build_time: "وقت البناء: 115 يوماً: 17 ساعة: 40 دقيقة",
        cost_per: "تكلفة السهم الواحد",
        soft_cap: "السقف المرن",
        soft_value: "22 منزلًا صغيرًا",
        security: "أمان",
        security_value: "احتياطي مثبت + بروتوكول Multising (آمن)",
        rent_value: "قيمة الإيجار",
        month: "شهر",
        goal: "هدف",
        invest_now: "استثمر الآن",
        passive: "دخل سلبي",
        passive_description: "كسب دخل من الإيجار وتلقي إيداعات ربع سنوية",
        transparency: "شفافية في العمليات",
        transparency_description: "نستخدم تكنولوجيا البلوكشين وتطبيق Dapp لفحص كل عملية وتفويض النفقات حسب مراحل البناء",
        multi_singnature: "أمان متعدد التوقيعات (22-41)",
        multi_singnature_description: "الأمان الرقمي متوافق قانونيًا وقابل للملمس للمستثمرين المؤهلين",
        home: "الصفحة الرئيسية",
        tech_details: "كيف يعمل",
        fwcc: "FWCC",
        pre: "ما قبل",
        sale: "البيع",
        sale_ens: "تنتهي البيع في",
        days: "أيام",
        hours: "ساعات",
        and: "and",
        minutes: "دقائق",
        connect: "توصيل",
        wallet: "محفظة",
        openfabric: "Openfabric AI",
        openfabric_description: "لوريم ايبسوم دولور sit أميت, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        vesting: "Vesting",
        vesting_description: "لوريم ايبسوم دولور sit أميت.",
        progress: "Progress",
        total_rasied: "الإجمالي المجمع",
        limited: "محدود",
        participants: "المشاركون",
        approve: "الموافقة",
        starts_in: "يبدأ في",
        referral: "Referral System",
        referral_description: "Share your referral link and instantly earn 5% in your wallet for each referred token purchase. The referral cas will be paid in the same token used for payment, USDT, USDC, or WBNIB",
        copy_link: "Copy Link",
        token_information: "معلومات الرمز",
        token_ticker: "رمز الرمز",
        token_supply: "إمداد الرمز",
        network: "الشبكة",
        token_address: "عنوان الرمز",
        decimal: "الكسور العشرية",
        token_distribution: "توزيع الرمز",
        min_allocation: "الحد الأدنى للتخصيص",
        max_allocation: "الحد الأقصى للتخصيص",
        min_swap_level: "مستوى التبديل الأدنى",
        access_type: "نوع الوصول",
        public: "عام"
      },
    }
    
  },
});

export default i18n;
